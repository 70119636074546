import { NgModule } from '@angular/core';
import {RouterModule, Routes} from "@angular/router";
import {AppLayoutComponent} from "./layout/app.layout.component";
import {AuthGuard} from "./components/shared/services/auth.guard";
import {AgentGuard} from "./components/shared/services/agent.guard";
import {EquipmentTypeModule} from "src/app/components/equipmentType/equipmentType.module";

const routes: Routes= [
  {
    path: '', component: AppLayoutComponent,
    children: [
      {
        path: '',  loadChildren: () => import('./components/login/login.module').then(m => m.LoginModule)
      },

      {
        path: 'loads',  loadChildren: () => import('./components/table/tabledemo.module').then(m => m.TableDemoModule),canActivate: [AuthGuard]
      },
      {

        path: 'loads/add',  loadChildren: () => import('./components/table-add-form/formlayoutdemo.module').then(m => m.FormLayoutDemoModule)
      },
      {
        path: 'equipment/add',  loadChildren: () => import('./components/equipmentType/equipmentType.module').then(m => m.EquipmentTypeModule)
      },
      {
        path: 'Driver',  loadChildren: () => import('./components/truckBoard/truckBoard.module').then(m => m.TruckBoardModule), canActivate: [AuthGuard]
      },
      {
        path: 'Carrier',  loadChildren: () => import('./components/carrier/carrier.module').then(m => m.CarrierModule), canActivate: [AuthGuard]
      },
     {
        path: 'Profile',  loadChildren: () => import('src/app/components/profile/profile.module').then(m => m.ProfileModule), canActivate: [AuthGuard, AgentGuard]
      },
      {
        path: 'MSCheck',
        loadChildren: () => import('src/app/components/mcCheck/brokerCarrierlist.module').then(m => m.BrokerCarrierlistModule),
        canActivate: [AuthGuard]
      }

    ]
  }
]

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
