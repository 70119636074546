import {NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";

import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {
  EquipmentTypeComponent
} from "src/app/components/equipmentType/components/equipmentType/equipmentType.component";

@NgModule({
  imports: [
    RouterModule.forChild([{ path: '', component: EquipmentTypeComponent }]),
  ],
  exports: [
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class EquipmentTypeRouterModule{}
