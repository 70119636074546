import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {FormLayoutDemoRoutingModule} from "src/app/components/table-add-form/formlayoutdemo-routing.module";
import {AutoCompleteModule} from "primeng/autocomplete";
import {CalendarModule} from "primeng/calendar";
import {ChipsModule} from "primeng/chips";
import {DropdownModule} from "primeng/dropdown";
import {InputMaskModule} from "primeng/inputmask";
import {InputNumberModule} from "primeng/inputnumber";
import {CascadeSelectModule} from "primeng/cascadeselect";
import {MultiSelectModule} from "primeng/multiselect";
import {InputTextareaModule} from "primeng/inputtextarea";
import {InputTextModule} from "primeng/inputtext";
import {ToastModule} from "primeng/toast";
import {FileUploadModule} from "primeng/fileupload";

import {CheckboxModule} from "primeng/checkbox";
import {RippleModule} from "primeng/ripple";

import {
  EquipmentTypeComponent
} from "src/app/components/equipmentType/components/equipmentType/equipmentType.component";
import {EquipmentTypeRouterModule} from "src/app/components/equipmentType/equipmentType-router.module";
import {EquipmentTypeService} from "src/app/components/equipmentType/services/equipmentType.service";
import {EffectsModule} from "@ngrx/effects";
import {EquipmentTypeEffect} from "src/app/components/equipmentType/store/effects/equipmentType.effect";
import {StoreModule} from "@ngrx/store";
import {reducers} from "src/app/components/equipmentType/store/reducers";
import {TableModule} from "primeng/table";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    EquipmentTypeRouterModule,
    AutoCompleteModule,
    CalendarModule,
    ChipsModule,
    DropdownModule,
    InputMaskModule,
    InputNumberModule,
    CascadeSelectModule,
    MultiSelectModule,
    InputTextareaModule,
    InputTextModule,
    ToastModule,
    FileUploadModule,
    CheckboxModule,
    RippleModule,
    EffectsModule.forFeature([EquipmentTypeEffect]),
    StoreModule.forFeature('equipmentType', reducers),
    TableModule,
  ],
  declarations: [EquipmentTypeComponent],
  exports: [
    EquipmentTypeComponent
  ],
  providers: [EquipmentTypeService]
})
export class EquipmentTypeModule{}
