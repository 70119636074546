<div class="layout-topbar">
    <div class="topbar-start">
        <button #menubutton type="button" class="topbar-menubutton p-link p-trigger" (click)="onMenuButtonClick()">
            <i class="pi pi-bars"></i>
        </button>

        <app-breadcrumb class="topbar-breadcrumb"></app-breadcrumb>
    </div>

    <div class="topbar-end">
        <ul class="topbar-menu">
          <!--  <li class="topbar-search">
      <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input type="text" pInputText placeholder="Search" class="w-12rem sm:w-full">
      </span>
  </li>
<li class="ml-3">
      <button pButton type="button" icon="pi pi-cog" class="p-button-text p-button-secondary p-button-rounded flex-shrink-0" (click)="onConfigButtonClick()"></button>
  </li>-->
<!--            <li class="topbar-profile">-->
<!--                <button *ngIf="SignIn" type="button" class="p-link" (click)="onProfileButtonClick()" >-->
<!--                  <img [alt]="(currentUserInfo$ | async)?.name" src="{{(currentUserInfo$ | async)?.avatar}}" class="w-2rem h-2rem mr-2" style="border-radius: 50% " />-->
<!--                </button>-->

<!--          <button-->
<!--                    (click)="LodOutClick()" style="margin-left: 10px">  <i class="layout-menuitem-icon pi pi-fw pi-sign-out" style="color:rgb(16, 185, 129)" ></i>-->
<!--              <span class="layout-menuitem-text" style="color: black"> Logout</span>-->


<!--            </button>-->
<!--             <a *ngIf="!SignIn"  (click)="LodOutClick()"  pRipple style="margin-left: 10px">  <i class="layout-menuitem-icon pi pi-fw pi-sign-out" style="color:rgb(16, 185, 129)"></i>-->
<!--                <span class="layout-menuitem-text" style="color: black">  Sign out</span>-->


<!--              </a>-->

<!--            </li>-->

          <li class="topbar-profile">
            <button type="button" class="p-link" (click)="onProfileButtonClick()">
              <img [src]="imageUrl" *ngIf="imageUrl" alt="Image" class="w-2rem h-2rem mr-2" style="border-radius: 50% " />
            </button>
          </li>

        </ul>
    </div>
</div>
