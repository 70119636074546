import {EquipmentTypeStateInterface} from "src/app/components/equipmentType/types/equipmentType-state.interface";

import {Action, createReducer, on} from "@ngrx/store";
import {
  equipmentTypeSetAction, equipmentTypeSetFailureAction,
  equipmentTypeSetSuccessAction
} from "src/app/components/equipmentType/store/actions/equipmentType-set.action";
import {
  equipmentTypeGetAction, equipmentTypeGetFailureAction,
  equipmentTypeGetSuccessAction
} from "src/app/components/equipmentType/store/actions/equipmentType-get.action";
import {state} from "@angular/animations";


const initState: EquipmentTypeStateInterface = {
  isSubmitting: false,
  equipmentTypes: [],
  error: null,
}

const equipmentTypeReducer = createReducer(
  initState,

  on(
    equipmentTypeSetAction, equipmentTypeGetAction,
    (state) => ({
      ...state,
      isSubmitting: true,
    })
  ),

  on(
    equipmentTypeSetSuccessAction, equipmentTypeSetFailureAction,
    (state) => ({
      ...state,
      isSubmitting: false,
    })
  ),

  on(
    equipmentTypeGetSuccessAction,
    (state, action): EquipmentTypeStateInterface =>
      ({
      ...state,
        isSubmitting: false,
        equipmentTypes: action.equipmentTypes
      })
  ),

  on(
    equipmentTypeGetFailureAction,
    (state, action) =>
      ({
        ...state,
        isSubmitting: false,
        equipmentTypes: [],
        error: action.errors
      })
  ),


)

export function reducers (state: EquipmentTypeStateInterface, action: Action){
  return equipmentTypeReducer(state,action)
}
