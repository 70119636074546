<div class="grid">

  <div class="card p-fluid col-18 layout-content">
    <h5>Available equipment types</h5>
    <br>
    <p-table [value]="equipmentTypes"  [rowHover]="true" styleClass="p-datatable-gridlines">
      <ng-template pTemplate="header" pTemplate="body" let-customer>

        <tr>
          <td>
            {{customer.name}}
          </td>
        </tr>
      </ng-template>
    </p-table>
    <br><br>



    <form [formGroup]="form" (ngSubmit)="submit()">

      <h5>Add equipment type</h5>


      <div class="test">
        <label class="col-18 mb-2 md:col-2 md:mb-0">
          Name
        </label>
        <div class="col-18 md:col-10">
          <input pInputText type="text" formControlName="equipmentType" />
        </div>
      </div>

      <div class="flex flex-wrap gap-2">
        <button pButton  label="Save" type="submit" [disabled]="!form.valid" ></button>
        <button pButton  label="Cancel" class="p-button-secondary" (click)="cancelForm()" ></button>
      </div>
    </form>
  </div>
</div>
