import {ChangeDetectorRef, Component, OnInit} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {FirebaseStorageService} from "src/app/components/shared/services/firebase-storage.service";
import {EquipmentTypeInterface} from "src/app/components/equipmentType/types/equipmentType.interface";
import {equipmentTypeSetAction} from "src/app/components/equipmentType/store/actions/equipmentType-set.action";
import {equipmentTypeGetAction} from "src/app/components/equipmentType/store/actions/equipmentType-get.action";
import {Observable} from "rxjs";
import {equipmentTypesInfoSelector} from "src/app/components/equipmentType/store/selectors";

@Component({
  templateUrl: './equipmentType.component.html',
  styleUrls: ['./equipmentType.component.scss']
})

export class EquipmentTypeComponent implements  OnInit{

  form: FormGroup
  equipmentTypes$: Observable<EquipmentTypeInterface[]>
  equipmentTypes: EquipmentTypeInterface[] = []



  constructor(
    private router: Router,
    private store: Store,
    private cdr: ChangeDetectorRef,
    private firebaseStorageService: FirebaseStorageService
  )
  {
    this.store.dispatch(equipmentTypeGetAction())
    this.equipmentTypes$ = this.store.select(equipmentTypesInfoSelector)



    this.form = new FormGroup({
      equipmentType: new FormControl('', [
        Validators.required
      ])
    })
  }
  ngOnInit(): void {
    this.equipmentTypes$.pipe(
    ).subscribe(
      (resp)=>{
        if(resp != null){
          this.equipmentTypes = resp
        }
      }
    )
  }

  submit(){
    if (this.form.value)
    {
      let request: EquipmentTypeInterface = {
        name: this.form.value.equipmentType
      }

      this.store.dispatch(equipmentTypeSetAction({params: request}))
      this.form.reset()
      // this.router.navigate(['/','loads']).then()
    }
  }

  cancelForm(): void {
    this.form.reset()
    this.router.navigate(['/','loads']).then()
  }


}
