import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";

import {catchError, map, of, switchMap, tap} from "rxjs";


import {HttpErrorResponse} from "@angular/common/http";

import {Router} from "@angular/router";
import {LoginService} from "../../services/login.service";
import {CurrentUserInterface} from "../../../shared/types/CurrentUser";
import {PersistanceService} from "../../../shared/services/persistance.service";
import {Store} from "@ngrx/store";
import {loginAfterAction} from "../actions/loginAfterActions";
import {
  getCurrentUserAction, getCurrentUserFAILUREAction,
  getCurrentUserSUCCESSAction
} from "src/app/components/login/store/actions/getCurrentUserActions";

@Injectable()
export class GetCurentUserEffect {
  constructor(private actions$: Actions,
              private loginService: LoginService,
              private persServ: PersistanceService,
              private router: Router,
              private store: Store) {}

 getCurrentUser$ = createEffect(()=>
  this.actions$.pipe(
    ofType(getCurrentUserAction),
    switchMap(()=>
      {
        return this.loginService.GetCurrentUser().pipe(

          map((currentUser:CurrentUserInterface) =>
          {
           // window.localStorage.setItem('accessToken', correntUser.idToken)
            this.persServ.set('accessToken', currentUser.idToken)
            return getCurrentUserSUCCESSAction({currentUser} )
          }),

          catchError((errorResponse: HttpErrorResponse)=>
          {
            return of(getCurrentUserFAILUREAction({errors: errorResponse.error.error.errors}))
          })
        )//end pipe
      })// end switchMap
    ) //end pipe
    )//end

  registrAfterSubmit$ = createEffect(
    ()=>
      this.actions$.pipe(
        ofType(getCurrentUserSUCCESSAction),
        tap(
          (currentUser)=>
          {
            const email =currentUser.currentUser.email;

           this.store.dispatch(loginAfterAction({email}));
          }
        )
      ),
    {dispatch: false}
  )

}
