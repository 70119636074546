import {createAction, props} from "@ngrx/store";
import {ActionTypes} from "src/app/components/table/store/actionTypes";
import {LoadListItemInterface} from "src/app/components/table-add-form/types/load-list-item.interface";
import {BackendErrorsInterface} from "src/app/components/shared/types/BackendError.Interface";

export const adminLoadListGetAction = createAction(
  ActionTypes.ADMIN_LOAD_LIST_GET,
  props<{params: Date}>()

)

export const adminLoadListGetSuccessAction = createAction(
  ActionTypes.ADMIN_LOAD_LIST_GET_SUCCESS,
  props<{loadList: LoadListItemInterface[]}>()
)

export const adminLoadListGetFailureAction = createAction(
  ActionTypes.ADMIN_LOAD_LIST_GET_FAILURE,
  props<{errors: BackendErrorsInterface}>()
)

