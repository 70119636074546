import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {PersistanceService} from "./persistance.service";
import {CurrentUserInfoInterface} from "../types/CurrentUser";
import {currentUserInfoSelector} from "../../login/store/selectors";
import {Store} from "@ngrx/store";



@Injectable({
  providedIn: 'root'
})
export class AgentGuard implements CanActivate {
  role: string ='';
  currentUserInfo$: Observable<CurrentUserInfoInterface | null>;
  constructor(private perstServ: PersistanceService, private router: Router,  private store: Store)
  {
    this.currentUserInfo$ = this.store.select(currentUserInfoSelector)

    this.currentUserInfo$.pipe(
    ).subscribe(
      (currentUserInfo)=>{
        if(currentUserInfo != null){
          this.role = currentUserInfo.role
        }
      }
    )
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(this.role === 'agent' || this.role == null || this.role == undefined || this.role == 'broker agent')
    {
      this.router.navigate(['/', ], {
          queryParams: {LoginAgain: true}
        }
      );
      return false;

    }

    else {
      return true;

    }
  }


}
