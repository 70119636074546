import {createAction, props} from "@ngrx/store";
import {ActionTypes} from "../action.types";
import {BackendErrorsInterface} from "../../../shared/types/BackendError.Interface";
import {CurrentUserInterface} from "../../../shared/types/CurrentUser";

export const getCurrentUserAction = createAction(
  ActionTypes.GET_CURRENT_USER
)

export const getCurrentUserSUCCESSAction = createAction(
  ActionTypes.GET_CURRENT_USER_SUCCESS,
  props<{currentUser:CurrentUserInterface}>()
)

export const getCurrentUserFAILUREAction = createAction(
  ActionTypes.GET_CURRENT_USER_FAILURE,
   props<{errors: BackendErrorsInterface}>()
)

