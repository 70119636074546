import {createAction, props} from "@ngrx/store";
import {ActionTypes} from "src/app/components/equipmentType/store/actionTypes";
import {EquipmentTypeInterface} from "src/app/components/equipmentType/types/equipmentType.interface";
import {BackendErrorsInterface} from "src/app/components/shared/types/BackendError.Interface";

export const equipmentTypeGetAction = createAction(
  ActionTypes.EQUIPMENT_TYPE_GET
)

export const equipmentTypeGetSuccessAction = createAction(
  ActionTypes.EQUIPMENT_TYPE_GET_SUCCESS,
  props<{ equipmentTypes: EquipmentTypeInterface[]}>()
)

export const equipmentTypeGetFailureAction = createAction(
  ActionTypes.EQUIPMENT_TYPE_GET_FAILURE,
  props<{errors: BackendErrorsInterface}>()
)
