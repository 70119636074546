<p-sidebar  [(visible)]="visible" position="right" [transitionOptions]="'.3s cubic-bezier(0, 0, 0.2, 1)'" styleClass="layout-profile-sidebar w-full sm:w-25rem">
    <div class="flex flex-column mx-auto md:mx-0">
        <span class="mb-2 font-semibold">Welcome </span>
        <span class="text-color-secondary font-medium mb-5">{{(currentUserInfo$ | async)?.name}}</span>


        <ul class="list-none m-0 p-0">
            <li>
                <a class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
                    <span>
                        <img [src]="imageUrl" *ngIf="imageUrl" alt="Image" class="w-2rem h-2rem mr-2" style="border-radius: 50% " />
                    </span>
                    <div class="ml-3">
                        <span class="mb-2 font-semibold">{{(currentUserInfo$ | async)?.americanName}}</span>
                        <p class="text-color-secondary m-0">{{(currentUserInfo$ | async)?.role}}</p>
                    </div>
                </a>
            </li>

            <li>
                <a class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
                    <span>
                        <i class="pi pi-user text-xl text-primary"></i>
                    </span>
                    <div class="ml-3">
                        <p class="text-color-secondary m-0">{{(currentUserInfo$ | async)?.email}}</p>
                        <p class="text-color-secondary m-0">{{(currentUserInfo$ | async)?.phoneNumber}}</p>
                    </div>
                </a>
            </li>

            <li>
              <a class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
                    <span>
                          <i class="pi pi-home text-xl text-primary"></i>
                    </span>
                    <div class="ml-3">
                      <span class="mb-2 font-semibold">{{(currentUserInfo$ | async)?.mc}}</span>
                      <p class="text-color-secondary m-0">{{(currentUserInfo$ | async)?.officeEmail}}</p>
                      <p class="text-color-secondary m-0">{{(currentUserInfo$ | async)?.officePhoneNumber}}</p>
                    </div>
              </a>
            </li>

            <li>
              <a class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
                      <span>
                            <i class="pi pi-info-circle text-xl text-primary"></i>
                      </span>
                <div class="ml-3">
                  <span class="mb-2 font-semibold"> EXT:  {{(currentUserInfo$ | async)?.ext}}</span>
                </div>
              </a>
            </li>

            <li *ngIf="role === 'admin'">
              <a (click)="addEquipmentType()" class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
                        <span>
                              <i class="pi pi-plus-circle text-xl text-primary"></i>
                        </span>
                <div class="ml-3">
                  <span class="mb-2 font-semibold"> Add Equipment Type</span>
                </div>
              </a>
            </li>

            <li>
                <a (click)="LogOutClick()" class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
                    <span>
                        <i class="pi pi-power-off text-xl text-primary"></i>
                    </span>
                    <div class="ml-3">
                        <span class="mb-2 font-semibold">Sign Out</span>
                    </div>
                </a>
            </li>


        </ul>
    </div>

</p-sidebar>
