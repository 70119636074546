import {Component, OnInit} from '@angular/core';
import {Store} from "@ngrx/store";
import {getCurrentUserAction} from "src/app/components/login/store/actions/getCurrentUserActions";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements  OnInit{
  title = 'shipp';

  constructor(private store: Store) {
  }

  ngOnInit(): void {
    this.store.dispatch(getCurrentUserAction())
  }


}
