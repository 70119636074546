import {createAction, props} from "@ngrx/store";
import {ActionTypes} from "../action.types";
import {BackendErrorsInterface} from "../../../shared/types/BackendError.Interface";
import { CurrentUserInfoInterface} from "../../../shared/types/CurrentUser";

export const loginAfterAction = createAction(
  ActionTypes.LOGIN_AFTER,
  props<{email:string}>()
)

export const loginAfterSUCCESSAction = createAction(
  ActionTypes.LOGIN_AFTER_SUCCESS,
  props<{currentUserInfo:CurrentUserInfoInterface | null}>()
)

export const loginAfterFAILUREAction = createAction(
  ActionTypes.LOGIN_AFTER_FAILURE,
   props<{errors: BackendErrorsInterface}>()
)
