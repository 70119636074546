import {Component, OnInit} from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import {LogoutAction} from "../components/login/store/actions/logoutAction";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {CurrentUserInfoInterface, CurrentUserInterface} from "../components/shared/types/CurrentUser";
import {currentUserInfoSelector, CurrentUserSelector} from "../components/login/store/selectors";
import {FirebaseStorageService} from "src/app/components/shared/services/firebase-storage.service";
import {Router} from "@angular/router";

@Component({
    selector: 'app-profilemenu',
    templateUrl: './app.profilesidebar.component.html'
})
export class AppProfileSidebarComponent implements OnInit{
  currentUser$: Observable<CurrentUserInterface | null>
  currentUserInfo$: Observable<CurrentUserInfoInterface | null>
  imageUrl: string = ''
  role: string = ''
    constructor(
      public layoutService: LayoutService,
      private store: Store,
      private firebaseStorageService: FirebaseStorageService,
      private router: Router) {

      this.currentUser$ = this.store.select(CurrentUserSelector)
      this.currentUserInfo$ = this.store.select(currentUserInfoSelector)

      this.currentUserInfo$.pipe().subscribe(x => {
        if(x !=null){
          this.role = x.role

          this.firebaseStorageService.getFileDownloadURL(x?.avatar)
            .subscribe(
              downloadURL => {

                const downloadTokens = downloadURL.downloadTokens;
                this.imageUrl = `https://firebasestorage.googleapis.com/v0/b/cargotransportation-71a70.appspot.com/o/${encodeURIComponent(downloadURL.name)}?alt=media&token=${downloadTokens}`

              },
              error => {
                console.error('Error getting download URL:', error);
              }
            )
        }
      })
    }
  ngOnInit() {

}

  get visible(): boolean {
        return this.layoutService.state.profileSidebarVisible;
    }

    set visible(_val: boolean) {
        this.layoutService.state.profileSidebarVisible = _val;
    }
  LogOutClick()
  {
    this.store.dispatch(LogoutAction());
  }
  addEquipmentType(){
    this.router.navigate(['equipment/add']).then();
  }
}
