import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {EquipmentTypeService} from "src/app/components/equipmentType/services/equipmentType.service";
import {Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {
  loadListItemSetAction, loadListItemSetFailureAction,
  loadListItemSetSuccessAction
} from "src/app/components/table-add-form/store/actions/load-list-item-set.action";
import {catchError, switchMap} from "rxjs/operators";
import {map, of} from "rxjs";
import {
  equipmentTypeSetAction, equipmentTypeSetFailureAction,
  equipmentTypeSetSuccessAction
} from "src/app/components/equipmentType/store/actions/equipmentType-set.action";
import {EquipmentTypeInterface} from "src/app/components/equipmentType/types/equipmentType.interface";
import {
  adminLoadListGetAction, adminLoadListGetFailureAction,
  adminLoadListGetSuccessAction
} from "src/app/components/table/store/actions/admin-load-list-get.action";
import {HttpErrorResponse} from "@angular/common/http";
import {
  equipmentTypeGetAction,
  equipmentTypeGetSuccessAction
} from "src/app/components/equipmentType/store/actions/equipmentType-get.action";

@Injectable()
export class EquipmentTypeEffect{

  constructor(
    private actions$: Actions,
    private equipmentTypeService: EquipmentTypeService,
    private router: Router,
    private store: Store
  ) {}

  equipmentTypeSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(equipmentTypeSetAction),
      switchMap(({ params }) => {

        return this.equipmentTypeService.addEquipmentType(params).pipe(
          map((response: EquipmentTypeInterface) => {
            console.log('in effect',response)
            return equipmentTypeSetSuccessAction({response})

          }),

          catchError(() => {
            return of(equipmentTypeSetFailureAction())
          })
        )
      })
    )
  )


  equipmentTypesGet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(equipmentTypeGetAction),
      switchMap(() => {
        return this.equipmentTypeService.getEquipmentTypes().pipe(
          map((resp) => {
            return equipmentTypeGetSuccessAction({equipmentTypes: resp})
          }),
          catchError((errorResponse: HttpErrorResponse) => {
            return of(adminLoadListGetFailureAction({errors: errorResponse.error.error.errors}))
          })
        )//end pipe
      })// end switchMap
    ) //end pipe
  )//end


}
