import {createFeatureSelector, createSelector} from "@ngrx/store";
import {EquipmentTypeStateInterface} from "src/app/components/equipmentType/types/equipmentType-state.interface";
import {state} from "@angular/animations";
import {EquipmentTypeInterface} from "src/app/components/equipmentType/types/equipmentType.interface";

export const equipmentTypeFeatureSelector = createFeatureSelector<EquipmentTypeStateInterface>(
  'equipmentType'
)

export const equipmentTypesInfoSelector = createSelector(
  equipmentTypeFeatureSelector,
  (state: EquipmentTypeStateInterface): EquipmentTypeInterface[] => state.equipmentTypes
)
