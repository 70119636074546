import {createAction, props} from "@ngrx/store";
import {ActionTypes} from "src/app/components/equipmentType/store/actionTypes";
import {EquipmentTypeInterface} from "src/app/components/equipmentType/types/equipmentType.interface";

export const equipmentTypeSetAction = createAction(
  ActionTypes.EQUIPMENT_TYPE_SET,
  props<{ params: EquipmentTypeInterface}>()
)

export const equipmentTypeSetSuccessAction = createAction(
  ActionTypes.EQUIPMENT_TYPE_SET_SUCCESS,
  props<{ response: EquipmentTypeInterface}>()
)

export const equipmentTypeSetFailureAction = createAction(
  ActionTypes.EQUIPMENT_TYPE_SET_FAILURE,
)
