import { Component, ElementRef, ViewChild } from '@angular/core';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import {Store} from "@ngrx/store";
import {LogoutAction} from "../components/login/store/actions/logoutAction";
import {Observable} from "rxjs";
import {CurrentUserInfoInterface, CurrentUserInterface} from "src/app/components/shared/types/CurrentUser";
import {currentUserInfoSelector, CurrentUserSelector} from "src/app/components/login/store/selectors";
import {FirebaseStorageService} from "src/app/components/shared/services/firebase-storage.service";

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopbarComponent {

    @ViewChild('menubutton') menuButton!: ElementRef;
  SignIn:boolean;

  currentUser$: Observable<CurrentUserInterface | null>
  currentUserInfo$: Observable<CurrentUserInfoInterface | null>
  imageUrl: string = ''

    constructor(public layoutService: LayoutService, private store: Store, private firebaseStorageService: FirebaseStorageService) {
      this.SignIn = false;
      this.currentUser$ = this.store.select(CurrentUserSelector)
      this.currentUserInfo$ = this.store.select(currentUserInfoSelector)
      this.currentUserInfo$.pipe().subscribe(x => {
        if(x !=null){

          this.firebaseStorageService.getFileDownloadURL(x?.avatar)
            .subscribe(
              downloadURL => {

                const downloadTokens = downloadURL.downloadTokens;
                this.imageUrl = `https://firebasestorage.googleapis.com/v0/b/cargotransportation-71a70.appspot.com/o/${encodeURIComponent(downloadURL.name)}?alt=media&token=${downloadTokens}`
                // Используйте этот URL для скачивания файла

              },
              error => {
                console.error('Error getting download URL:', error);
              }
            )
        }
      })

    }

    onMenuButtonClick() {
        this.layoutService.onMenuToggle();
    }

    onProfileButtonClick() {
        this.layoutService.showProfileSidebar();
    }
    onConfigButtonClick() {
        this.layoutService.showConfigSidebar();
    }
  LodOutClick()
  {
    this.store.dispatch(LogoutAction());
  }

}
