export const environment = {
  apiKey: "AIzaSyAknMm2KFXI2Zhral-mUa79eoohsyFEBHo",
  FbUrl: "https://cargotransportation-71a70-default-rtdb.firebaseio.com",
  firebaseConfig :{

    apiKey: "AIzaSyAknMm2KFXI2Zhral-mUa79eoohsyFEBHo",

    authDomain: "cargotaseapp.com",

    databaseURL: "https://cargotransportation-71a70-default-rtdb.firebaseio.com",

    projectId: "cargotransportation-71a70",

    storageBucket: "cargotransportation-71a70.appspot.com",

    messagingSenderId: "263635964978",

    appId: "1:263635964978:web:896c3b201307ae0bd6982b"

  }
};
