export enum ActionTypes {
  ADMIN_LOAD_LIST_GET = '[Load List] Admin load list get',
  ADMIN_LOAD_LIST_GET_SUCCESS = '[Load List] Admin load list get success',
  ADMIN_LOAD_LIST_GET_FAILURE = '[Load List] Admin load list get failure',

  AGENT_LOAD_LIST_GET = '[Load List] Agent load list get',
  AGENT_LOAD_LIST_GET_SUCCESS = '[Load List] Agent load list get success',
  AGENT_LOAD_LIST_GET_FAILURE = '[Load List] Agent load list get failure',

  MANAGER_LOAD_LIST_GET = '[Load List] Manager oad list get',
  MANAGER_LOAD_LIST_GET_SUCCESS = '[Load List] Manager load list get success',
  MANAGER_LOAD_LIST_GET_FAILURE = '[Load List] Manager load list get failure',
}
