import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import {Observable} from "rxjs";
import {CurrentUserInfoInterface} from "../components/shared/types/CurrentUser";
import {currentUserInfoSelector} from "../components/login/store/selectors";
import {Store} from "@ngrx/store";

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: any[] = [];
  role: string ='';
  currentUserInfo$: Observable<CurrentUserInfoInterface | null>;
  constructor(private store: Store)
  {
    this.currentUserInfo$ = this.store.select(currentUserInfoSelector)

    this.currentUserInfo$.pipe(
    ).subscribe(
      (currentUserInfo)=>{
        if(currentUserInfo){
          this.role = currentUserInfo.role
          if (this.role === 'agent') {
            this.model = [

              {
                label: 'Loads',
                icon: 'pi pi-fw pi-share-alt',
                routerLink: ['/loads'],

              },
              {
                label: 'Truck board',
                icon: 'pi pi-fw pi-truck',
                routerLink: ['/Driver'],

              },
              {
                label: 'Carrier',
                icon: 'pi pi-fw pi-users',
                routerLink: ['/Carrier'],

              },

              {
                label: 'MC check',
                icon: 'pi pi-fw pi-check-circle',
                routerLink: ['/MSCheck']
              },


            ];
          } else {
            if (this.role === 'admin' || this.role === 'manager') {
              this.model = [

                {
                  label: 'Loads',
                  icon: 'pi pi-fw pi-share-alt',
                  routerLink: ['/loads'],

                },
                {
                  label: 'Truck board',
                  icon: 'pi pi-fw pi-truck',
                  routerLink: ['/Driver'],

                },
                {
                  label: 'Carrier',
                  icon: 'pi pi-fw pi-users',
                  routerLink: ['/Carrier'],

                },
                {
                  label: 'Profile',
                  icon: 'pi pi-fw pi-user',
                  routerLink: ['/Profile'],

                },
                {
                  label: 'MC check',
                  icon: 'pi pi-fw pi-check-circle',
                  routerLink: ['/MSCheck']
                },


              ];
            }
            else
            {
              if (this.role === 'broker manager')
              {
                this.model = [

                  {
                    label: 'Loads',
                    icon: 'pi pi-fw pi-share-alt',
                    routerLink: ['/loads'],

                  },

                  {
                    label: 'Profile',
                    icon: 'pi pi-fw pi-user',
                    routerLink: ['/Profile'],

                  },
                  {
                    label: 'MC check',
                    icon: 'pi pi-fw pi-check-circle',
                    routerLink: ['/MSCheck']
                  },


                ];
              }
              else {
                if (this.role === 'broker agent')
                {
                  this.model = [

                    {
                      label: 'Loads',
                      icon: 'pi pi-fw pi-share-alt',
                      routerLink: ['/loads'],

                    },


                    {
                      label: 'MC check',
                      icon: 'pi pi-fw pi-check-circle',
                      routerLink: ['/MSCheck']
                    },


                  ];
                }
                else
                {
                  if (this.role === 'accounting')
                  {
                    this.model = [

                      {
                        label: 'Loads',
                        icon: 'pi pi-fw pi-share-alt',
                        routerLink: ['/loads'],

                      }]
                  }
                  else{
                    if (this.role === 'support')
                    {
                      this.model = [

                        {
                          label: 'Truck board',
                          icon: 'pi pi-fw pi-truck',
                          routerLink: ['/Driver'],

                        },
                        {
                          label: 'Carrier',
                          icon: 'pi pi-fw pi-users',
                          routerLink: ['/Carrier'],

                        },
                        ]
                    }
                  }

                }
              }
            }}

        }
      }
    )
  }

    ngOnInit() {




    }
}
