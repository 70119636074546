import { Injectable } from '@angular/core';
import {AngularFireStorage} from "@angular/fire/compat/storage";
import {PersistanceService} from "./persistance.service";
import {catchError, finalize, Observable} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";


@Injectable({
  providedIn: 'root'
})
export class FirebaseStorageService {

  constructor(private storage: AngularFireStorage,
              private persServ: PersistanceService,
              private http: HttpClient) { }

  /*uploadFile(filePath: string, file: File) {
    const task = this.storage.upload(filePath, file);
    return task;
  }*/

  uploadFileWithToken(file: File,  filePath: string) {
    const token=this.persServ.get('accessToken');
    const url = `https://firebasestorage.googleapis.com/v0/b/cargotransportation-71a70.appspot.com/o?name=${encodeURIComponent(filePath)}`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });

    const formData = new FormData();
    formData.append('file', file);

    return this.http.post(url, formData, { headers: headers }).pipe(
      finalize(() => {
        // Действия после завершения загрузки файла
      })
    );
  }

 /* getFileDownloadURL(filePath: string) {
    //const fileRef = this.storage.ref(filePath);
  //  return fileRef.getDownloadURL();
    const token=this.persServ.get('accessToken');
    const url = `https://firebasestorage.googleapis.com/v0/b/cargotransportation-71a70.appspot.com/o/${encodeURIComponent(filePath)}?alt=media&token=${token}`;
    return this.http.get(url, { responseType: 'blob' });
  }*/
  getFileDownloadURL(fileUrl: string): Observable<any> {
    const accessToken = this.persServ.get('accessToken');
    const url = `https://firebasestorage.googleapis.com/v0/b/cargotransportation-71a70.appspot.com/o?name=${encodeURIComponent(fileUrl)}`
    if (!accessToken) {
      throw new Error('Access token not found.');
    }

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.get(url, { headers: headers })
      .pipe(
        catchError(error => {
          throw new Error('Error downloading file: ' + error.message);
        })
      );
  }
}
