export enum ActionTypes {
  LOGIN = '[Login] Login set',
  LOGIN_SUCCESS = '[Login] Login set success',
  LOGIN_FAILURE = '[Login} Login set failure',

  LOGIN_AFTER = '[Login] Login after set',
  LOGIN_AFTER_SUCCESS = '[Login] Login after set success',
  LOGIN_AFTER_FAILURE = '[Login} Login after set failure',

  LOGIN_USER_LIST_ACTION = '[Login] Login get user list ',
  LOGIN_USER_LIST_ACTION_SUCCESS = '[Login] Login get user list success',
  LOGIN_USER_LIST_ACTION_FAILURE = '[Login] Login get user list',

  GET_CURRENT_USER = '[Login] get current user',
  GET_CURRENT_USER_SUCCESS = '[Login] get current user success',
  GET_CURRENT_USER_FAILURE = '[Login} get current user failure',

  LOGOUT= '[Login] LOGOUT',
}

