import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {EquipmentTypeInterface} from "src/app/components/equipmentType/types/equipmentType.interface";
import {map, Observable} from "rxjs";
import {environment} from "src/environments/environment";

@Injectable()
export class EquipmentTypeService {

  constructor(private http: HttpClient) {
  }

  addEquipmentType(post: EquipmentTypeInterface): Observable<EquipmentTypeInterface> {

    return this.http.post<any>(`${environment.FbUrl}/EquipmentType.json`, post).pipe(
      map( (resp) => {
        const request: EquipmentTypeInterface = {
          // id: resp.name,
          name: post.name
        }
        return request
      })
    )
  }///

  getEquipmentTypes(): Observable<EquipmentTypeInterface[]>{

    return  this.http.get<any>(`${environment.FbUrl}/EquipmentType.json`).pipe(
      map( (response: {[key: string]: EquipmentTypeInterface}) => {
        if(response)
        {
          return Object
            .keys(response)
            .map(key => ({
              // id: key,
              name: response[key].name,
            }))
        }
        else
        {
          const responseArray: EquipmentTypeInterface[] = [];
          return responseArray
        }
      })
    )
  }///

}
