import {NgModule, isDevMode, Provider} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import {AppLayoutModule} from "./layout/app.layout.module";
import { AppRoutingModule } from './app-routing.module';
import {StoreDevtoolsModule} from "@ngrx/store-devtools";
import {StoreModule} from "@ngrx/store";
import {EffectsModule} from "@ngrx/effects";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {AuthInterceptor} from "./components/shared/services/auth.interceptor";
import {GetCurentUserEffect} from "src/app/components/login/store/effects/get-curent-user-effect";
import {LoginService} from "src/app/components/login/services/login.service";

import { ServiceWorkerModule } from '@angular/service-worker';
import { HashLocationStrategy, LocationStrategy  } from '@angular/common';

import {CommonModule} from "@angular/common";
import {environment} from "../environments/environment";
import {AngularFireModule} from "@angular/fire/compat";
import {AngularFireStorageModule} from "@angular/fire/compat/storage";
import {EquipmentTypeModule} from "./components/equipmentType/equipmentType.module";



const InterserverProvide : Provider = {
  provide: HTTP_INTERCEPTORS,
  multi:true,
  useClass: AuthInterceptor

}



@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppLayoutModule,
    AppRoutingModule,
    CommonModule,
    EquipmentTypeModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireStorageModule,
    StoreModule.forRoot(),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
    EffectsModule.forRoot([GetCurentUserEffect]),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [InterserverProvide, LoginService,  {provide : LocationStrategy , useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
