import {createFeatureSelector, createSelector} from "@ngrx/store";

import {LoginStateInterface} from "../types/loginStateInterface";
import {CurrentUserInterface, CurrentUserInfoInterface} from "../../shared/types/CurrentUser";

export const loginFeatureSelector = createFeatureSelector< LoginStateInterface>(
  'login'
)

export const IsLoginSelector = createSelector(
  loginFeatureSelector,
  (state: LoginStateInterface): boolean | null=> state.IsLoggedIn
)
export const CurrentUserSelector = createSelector(
  loginFeatureSelector,
  (state: LoginStateInterface): CurrentUserInterface | null=> state.currentUser
)

export const currentUserInfoSelector = createSelector(
  loginFeatureSelector,
  (state: LoginStateInterface): CurrentUserInfoInterface | null => state.currentUserInfo
)

export const userListSelector = createSelector(
  loginFeatureSelector,
  (state: LoginStateInterface): CurrentUserInfoInterface[] => state.userList
)

export const isLoadingSelector = createSelector(
  loginFeatureSelector,
  (state: LoginStateInterface): boolean => state.isLoading
)
